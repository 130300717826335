import { createContext, useEffect, useState } from "react";
// import { food_list } from "../assets/assets";
import axios from "axios";

export const StoreContext = createContext(null)

const StoreContextProvider = (props) => {

    //const [cartItems, setCartItems] = useState({}); 
    const url = "https://server.ersthelfer-band.at";
    const [token, setToken] = useState("");
    const [food_list, setFoodList] = useState([]); 
    const [food1_list, setFood1List] = useState([]); 

    const [food2_list, setFood2List] = useState([]); 

    /* const addToCart = async (itemId) => {
        if(!cartItems[itemId]) {
            setCartItems((prev)=>({...prev, [itemId]:1}))
        }
        else {
            setCartItems((prev)=>({...prev, [itemId]:prev[itemId]+1}))
        }
        if(token) {
            await axios.post(url+"/api/cart/add", {itemId}, {headers:{token}})
        }
    }

    const removeFromCart = async (itemId) => {
        setCartItems((prev)=>({...prev, [itemId]:prev[itemId]-1})); 
        if (token) {
            await axios.post(url+"/api/cart/remove", {itemId}, {headers:{token}})
        }
    }

    const getTotalCartAmount = () => {
        let totalAmount = 0; 
        for(const item in cartItems)
        {
            if(cartItems[item]>0) {
                let itemInfo = food_list.find((product)=>product._id === item)
                totalAmount += itemInfo.price* cartItems[item]; 
            }
            
        }
        return totalAmount; 
    } */

    const fetchFoodList = async () => {
        const response = await axios.get(url+"/api/food/list");
        setFoodList(response.data.data)
    }

    const fetchFood1List = async () => {
        const response = await axios.get(url+"/api/food1/list");
        setFood1List(response.data.data)
    }

    const fetchFood2List = async () => {
        const response = await axios.get(url+"/api/food2/list");
        setFood2List(response.data.data)
    }

   /*  const loadCartData = async (token) => {
        const response = await axios.post(url+"/api/cart/get", {}, {headers:{token}}); 
        setCartItems(response.data.cartData); 
    } */

    useEffect(()=>{
        async function loadData() {
            await fetchFoodList();
            if (localStorage.getItem("token")) {
                setToken(localStorage.getItem("token"));
                //await loadCartData(localStorage.getItem("token")); 
            }

            await fetchFood1List();
            if (localStorage.getItem("token")) {
                setToken(localStorage.getItem("token"));
                //await loadCartData(localStorage.getItem("token")); 
            }

            await fetchFood2List();
            if (localStorage.getItem("token")) {
                setToken(localStorage.getItem("token"));
                //await loadCartData(localStorage.getItem("token")); 
            }
        }
        loadData(); 
    },[])

    const contextValue = {
        food_list, 
        food1_list,
        food2_list,
        /* cartItems, 
        setCartItems, 
        addToCart, 
        removeFromCart, 
        getTotalCartAmount,  */
        url, 
        token, 
        setToken
    }
    
    return (
        <StoreContext.Provider value={contextValue}>
            {props.children}
        </StoreContext.Provider>
    )
}

export default StoreContextProvider; 