import React, {useState, useContext} from 'react';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import './imagesandvideos.css';

import FacebookIcon from '../../assets/facebook-icon.png';
import YouTubeIcon from '../../assets/youtube.png';
import InstagramIcon from '../../assets/instagram.png';
import CloseIcon from '../../assets/Icon_Close.png';
import RightIcon from '../../assets/Icon_Arrow_Right.png';
import LeftIcon from '../../assets/Icon_Arrow_Left.png';

import { StoreContext } from '../../context/StoreContext.jsx'

const imagesImport = require.context('../../images', true);
const images = imagesImport.keys().map(image => imagesImport(image));

const url = "https://server.ersthelfer-band.at";

export const ImagesAndVideos = () => {

    const {food2_list} = useContext(StoreContext)
    const images = food2_list.map(item => url+"/images/"+item.image);

  const [data, setData] = useState({img: '', i: 0})

    const viewImage = (img, i)=>{
        setData({img, i});
    }

    const imgAction = (action) => {
        let i = data.i;
        if(action === "next-img"){
            setData({img: images[i + 1], i: i + 1});
        }
        if(action === "previous-img"){
            setData({img: images[i - 1], i: i - 1});
        }
        if(!action){
            setData({img: '', i: 0});
        }
    }
    console.log(food2_list);

  return (
        <section id="imgandvid">
            <h1 className='imgandvidTitle'>Bilder</h1>
            <div className="imgandvidBody">
              <>
            {data.img &&
                <div className='previewImg' /* style={{
                    width: "100%", 
                    height: "100vh",
                    background: "black",
                    position: "fixed", 
                    display: "flex", 
                    justifyContent: "center", 
                    alignItems: "center",
                    overflow: "hidden",
                }} */>
                    <button className='previewClose' onClick={() => imgAction()} ><img className='previewIconClose' src={CloseIcon} alt="Close" /></button>
                    <button className='previewLeft' onClick={() => imgAction("previous-img")}><img className='previewIcon' src={LeftIcon} alt="Left" /></button>
                    <img className='previewImage' src={data.img} />
                    <button className='previewRight' onClick={() => imgAction("next-img")}><img className='previewIcon' src={RightIcon} alt="Right" /></button>
                </div>
            }

            <div className='ImagesDesktop' style={{padding: '0px', maxWidth: "100rem"
            }}>
            <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}>
            <Masonry gutter="0px">
                {food2_list.slice().reverse().map((item, index)=>(
                    <img
                        className='mainImg'
                        key={index}
                        src={url+"/images/"+item.image}
                        //style={{width: "100%", display: "block", cursor: "pointer"}}
                        alt=""
                        onClick={()=>viewImage(url+"/images/"+item.image, index)}
                    />
                ))}
            </Masonry>
            </ResponsiveMasonry>
            </div>
            <div className='ImagesMobile' style={{padding: '0px', maxWidth: "100rem"
            }}>
            <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}>
            <Masonry gutter="10px">
                {food2_list.slice().reverse().map((item, index)=>(
                    <img
                        className='mainImg'
                        key={index}
                        src={item.image}
                        /* style={{width: "100%", display: "block", cursor: "pointer"}} */
                        alt=""
                        /* onClick={()=>viewImage(image, i)} */
                    />
                ))}
            </Masonry>
            </ResponsiveMasonry>
            </div>
        
        </>

<p className='imgandvidSmall'>Weitere Bilder findest du auf unseren Social Media Kanälen.</p>

        
            
            <div className="links">

                    <a href="https://www.facebook.com/people/Ersthelfer/61562495922686/" target="_blank"><img src={FacebookIcon} alt="Facebook" className="link" /></a>
                    <a href="https://www.instagram.com/ersthelfer.band/" target="_blank"><img src={InstagramIcon} alt="Instagram" className="link" /></a>
                    <a href="https://www.youtube.com/channel/UCrFbCgg0WLE2sqxIcBjWXfw" target="_blank"><img src={YouTubeIcon} alt="YouTube" className="link" /></a>
                    
                    
                </div>
                
                </div>
            
        </section>
  );
};


/* {images.map((image, i) => (
    <img
    className='mainImg'
        key={i}
        src={image}
        /* style={{width: "100%", display: "block", cursor: "pointer"}} 
        alt=""
        /* onClick={()=>viewImage(image, i)} 
    /> 
))} */