import React, {useState, useContext} from 'react';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import './videos.css';
import YoutubeEmbed from "../YTEmbed";

import FacebookIcon from '../../assets/facebook-icon.png';
import YouTubeIcon from '../../assets/youtube.png';
import InstagramIcon from '../../assets/instagram.png';

import { StoreContext } from '../../context/StoreContext.jsx'



export const Videos = () => {

  const {food1_list} = useContext(StoreContext)

  return (
        <section id="videos">
            <h1 className='videosTitle'>Videos</h1>
            <div className="videosBody">
            {/* <YoutubeEmbed embedId="kooA3MuDf8Q" /> */}

            {food1_list.slice().reverse().map((item, index)=>{
                        
                        return(
                          <YoutubeEmbed key={index} className="ytemb" embedId={item.name} />
                          )
                      
                      
                    })}


<p className='videosSmall'>Weitere Videos findest du auf unseren Social Media Kanälen.</p>

        
            
            <div className="videoslinks">

                    <a href="https://www.facebook.com/people/Ersthelfer/61562495922686/" target="_blank"><img src={FacebookIcon} alt="Facebook" className="link" /></a>
                    <a href="https://www.instagram.com/ersthelfer.band/" target="_blank"><img src={InstagramIcon} alt="Instagram" className="link" /></a>
                    <a href="https://www.youtube.com/channel/UCrFbCgg0WLE2sqxIcBjWXfw" target="_blank"><img src={YouTubeIcon} alt="YouTube" className="link" /></a>
                    
                    
                </div>
                
                </div>
            
        </section>
  );
};
